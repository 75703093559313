
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































.accordion,
[class*='accordion--'] {
  @extend %border-radius;

  padding: 2rem 1.5rem;
  border: 1px solid $c-gray-light;

  @include mq(m) {
    padding: 2.5rem 3rem;
  }
}

.accordion__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: transparent;
  border: 0;
  cursor: pointer;

  .icon {
    width: 3.6rem;
    height: 3.6rem;
    transition: transform 0.15s;
  }

  .is-open & {
    .icon {
      transform: scale(-1);
    }
  }

  @include mq(m) {
    .icon {
      width: 4.8rem;
      height: 4.8rem;
    }
  }
}

.meter-history .accordion__label {
  font-size: 16px !important;
  transition: 0.2s;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
}

.meter-history .is-open .accordion__label {
  position: relative;
  top: -10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bababa;
}

.accordion__content {
  overflow: hidden;
  height: 0;

  .is-open & {
    height: auto;
  }
}

.accordion__content-inner {
  margin-top: 2.5rem;
}

.meter-history {
  tr:not(:last-child) .table__td {
    border-bottom-style: dashed;
  }
}
