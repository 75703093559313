
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































.meter-history,
[class*='meter-history--'] {
  padding-top: 0;
}

.table {
  display: table;
  border-collapse: collapse;
}

.table__th {
  @extend %fw-normal;
  // prettier-ignore
  @include fluid(font-size, (xxs: 9px, m: 11px));

  padding: 0 0.5rem 2rem;
  color: $c-orange;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.table__td {
  padding: 1.5rem 0.5rem;

  tr:not(:last-child) & {
    border-bottom: 1px solid $c-gray-light;
  }
}
